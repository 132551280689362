<template>
  <div>
    <div>
      <h1 class="mr-sm-4 header-tablepage">THEME</h1>
    </div>
    <div class="title-tabs mt-3">Theme Setting</div>

    <div class="no-gutters bg-white p-3 row" v-if="isLoading">
      <UploadImageAndPreview
        :imagePath="imagePath"
        v-for="ele of listElememt"
        :textFloat="ele.title"
        :desc="ele.desc"
        :key="ele.key"
        :name="ele.key"
        v-model="form[ele.key]"
        :value="form[ele.key]"
        class="mb-3 col-md-6"
        @deleteImage="deleteImage"
      />
      <!-- :ratio="ele.ratio" -->
      <div class="break-normal"></div>
      <div class="col-md-6">
        <div class="mb-3">
          <ColorPicker
            textFloat="Primary Color"
            v-model="form.themePrimaryColor"
            :value="form.themePrimaryColor"
            name="primary_color"
          />
        </div>
        <div class="mb-3">
          <ColorPicker
            textFloat="Secondary Color (Booth & Backoffice)"
            v-model="form.themeSecondaryColor"
            :value="form.themeSecondaryColor"
            name="secondary_color"
          />
        </div>
        <div class="mb-3">
          <ColorPicker
            textFloat="Secondary Color (Line & Campaign)"
            v-model="form.themeSecondaryColorLine"
            :value="form.themeSecondaryColorLine"
            name="secondary_color"
          />
        </div>
        <div class="mb-3">
          <ColorPicker
            textFloat="Light Primary Color"
            v-model="form.themeLightPrimaryColor"
            :value="form.themeLightPrimaryColor"
            name="font_color"
          />
        </div>
        <div class="mb-3">
          <ColorPicker
            textFloat="Disable Color"
            v-model="form.themeDisableColor"
            :value="form.themeDisableColor"
            name="disable_color"
          />
        </div>
      </div>
      <div class="col-md-6">
        <b-form-group>
          <template #label>
            <div class="label">Background</div>
          </template>
          <b-form-radio-group
            id="radio-slots"
            v-model="form.themeBackgroundPage"
            stacked
            name="radio-options-slots"
          >
            <b-form-radio name="some-radios" value="1"
              >Solid Color</b-form-radio
            >
            <b-form-radio name="some-radios" value="2"
              >Image <small></small
            ></b-form-radio>
          </b-form-radio-group>
        </b-form-group>

        <ColorPicker
          v-if="form.themeBackgroundPage == 1"
          v-model="form.themeSolidColor"
          :value="form.themeSolidColor"
          name="theme-color"
        />
        <UploadImageAndPreview
          class="mb-3"
          name="themeBackGround"
          v-if="form.themeBackgroundPage == 2"
          desc="suggests Dimension 260x1190 px not over 1 MB (Please attach file .png only)"
          v-model="form.themeBackGround"
          :value="form.themeBackGround"
          @deleteImage="deleteImage"
          :imagePath="imagePath"
        />
        <div class="my-3">
          <ColorPicker
            textFloat="Navigation Bar Color"
            v-model="form.themeNavigationBarColor"
            :value="form.themeNavigationBarColor"
            name="disable_color"
          />
        </div>
        <div class="mb-3">
          <ColorPicker
            textFloat="Bar Color (Line)"
            v-model="form.bar_color"
            :value="form.bar_color"
            name="bar-color"
          />
        </div>
        <div class="mb-3">
          <ColorPicker
            textFloat="Font Color (Line)"
            v-model="form.font_color"
            :value="form.font_color"
            name="font-color"
          />
        </div>
        <div class="mb-3">
          <ColorPicker
            textFloat="Button Color (Line)"
            v-model="form.button_color"
            :value="form.button_color"
            name="button-color"
          />
        </div>
      </div>

      <UploadImageAndPreview
        name="themeTransactionCodeEarn"
        textFloat="Earn Point"
        class="col-md-6"
        desc="Banner from Transaction Code page suggests dimension 344x258 px not over 1 MB (Please attach file .png only)"
        v-model="form.themeTransactionCodeEarn"
        :value="form.themeTransactionCodeEarn"
        @deleteImage="deleteImage"
        :imagePath="imagePath"
      />
      <UploadImageAndPreview
        name="themeTransactionCodeError"
        textFloat="Error Point"
        class="col-md-6"
        desc="Banner from Transaction Code page suggests dimension 344x258 px not over 1 MB (Please attach file .png only)"
        v-model="form.themeTransactionCodeError"
        :value="form.themeTransactionCodeError"
        @deleteImage="deleteImage"
        :imagePath="imagePath"
      />
    </div>
    <div class="title-tabs">Theme Setting</div>

    <div class="no-gutters bg-white p-3" v-if="isLoading">
      <UploadImageAndPreview
        name="themeTransactionCodeBanner"
        textFloat="Transaction Code Banner"
        desc="Banner from Transaction Code page suggests dimension 366x206px (ratio16:9) not over 1 MB (Please attach file .png only)"
        v-model="form.themeTransactionCodeBanner"
        :value="form.themeTransactionCodeBanner"
        @deleteImage="deleteImage"
        :imagePath="imagePath"
      />
      <b-row no-gutters>
        <b-col md="6" cols="12">
          <UploadImageAndPreview
            name="mission_success_icon"
            textFloat="Mission Stamp Icon Success"
            desc="Banner from Transaction Code page suggests dimension 344x258 px not over 1 MB (Please attach file .png only)"
            v-model="form.mission_success_icon"
            :value="form.mission_success_icon"
            @deleteImage="deleteImage"
            :imagePath="imagePath"
          />
        </b-col>
        <b-col md="6" cols="12">
          <UploadImageAndPreview
            name="mission_unsuccess_icon"
            textFloat="Mission Stamp Icon Unsuccess"
            desc="Banner from Transaction Code page suggests dimension 344x258 px not over 1 MB (Please attach file .png only)"
            v-model="form.mission_unsuccess_icon"
            :value="form.mission_unsuccess_icon"
            @deleteImage="deleteImage"
            :imagePath="imagePath"
          />
        </b-col>
      </b-row>
    </div>
    <FooterAction routePath="/setting" @submit="saveForm()" />
    <ModalLoading ref="modalLoading" />
  </div>
</template>
<script>
import ModalLoading from "@/components/modal/ModalLoading";
import UploadImageAndPreview from "@/components/inputs/UploadImageAndPreview";
import ColorPicker from "@/components/inputs/InputColorPicker";
export default {
  components: { UploadImageAndPreview, ModalLoading, ColorPicker },
  data() {
    return {
      form: {
        themeLogoLine: "",
        themeLogoLoginPage: "",
        themeLogoHomePage: "",
        themePrimaryColor: "",
        themeLightPrimaryColor: "",
        themeSecondaryColor: "",
        themeSecondaryColorLine: "",
        themeDisableColor: "",
        themeNavigationBarColor: "",
        themeBackgroundPage: "1",
        themeSolidColor: "",
        themeBackGround: "",
        themeTransactionCodeEarn: "",
        themeTransactionCodeError: "",
        themeTransactionCodeBanner: "",
      },
      imagePath: "",
      listElememt: [
        {
          title: "Logo Login Booth",
          desc: "Logo from login page suggests dimension 800x800 not over 1 MB (Please attach file .png only)",
          key: "themeLogoLoginPage",
          ratio: {
            width: 800,
            height: 800,
          },
        },
        {
          title: "Logo Booth",
          desc: "Logo in main page suggests dimension 120x30 not over 1 MB (Please attach file .png only)",
          key: "themeLogoHomePage",
          ratio: {
            width: 120,
            height: 30,
          },
        },
        {
          title: "Logo Liff",
          desc: "Logo from line application suggests dimension 390x96 not over 1 MB (Please attach file .png only)",
          key: "themeLogoLine",
          ratio: {
            width: 390,
            height: 96,
          },
        },
        {
          title: "Logo Print",
          desc: "Logo in transaction bill suggests dimension 120x30 not over 1 MB (Please attach file .png only)",
          key: "themePrintLogo",
          ratio: {
            width: 120,
            height: 30,
          },
        },
        {
          title: "Point Logo",
          desc: "",
          key: "point_logo",
        },
      ],
      isLoading: false,
    };
  },
  mounted() {},

  created() {
    this.getList();
  },
  destroyed() {},
  methods: {
    async getList() {
      const response = await this.axios(`/Setting/theme`);
      this.imagePath = response.data.themeLogoDomain;
      this.form = response.data.result;
      this.isLoading = true;
    },
    deleteImage(key) {
      this.form[key] = "";
    },
    async removeValueObj() {
      for (const [key, value] of Object.entries(this.form)) {
        if (value == this.freezeForm[key]) {
          delete this.freezeForm[key];
        } else if (value) {
          this.freezeForm[key] = value;
        }
      }
    },
    async saveForm() {
      this.$refs.modalLoading.show();
      const result = await this.axios.post(`/setting/updatetheme`, this.form);
      if (result.data.result == 1) {
        this.$bus.$emit("getTheme");
        this.successAlert().then((val) => val && this.$router.push("/setting"));
      } else {
        this.errorAlert(result.data.message);
      }
      this.$refs.modalLoading.hide();
    },
  },
};
</script>
<style>
label.title {
  color: #626266;
  font-size: 16px;
  margin-bottom: 2px;
  /* font-weight: bold; */
}
</style>
